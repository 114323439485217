<template>
    <div class="policy">
        <p>更新日期：2024年10月21日</p>
        <p>生效日期：2024年10月21日</p>
        <p><strong>运营者信息</strong></p>
        <p>睿云联（厦门）网络通讯技术有限公司（以下简称“ 睿云联”或“我们”），注册地址位于厦门火炬高新区软件园创新大厦C区3F-A917，办公地址位于厦门市思明区软件园二期观日路56号10楼。</p>
        <p><strong>引言</strong></p>
        <p>我们非常重视您的隐私。本《隐私政策》（以下简称“本政策”）旨在说明我们将如何收集、使用、保存、披露、保护您的个人信息。请您在使用睿云联产品或者服务前，务必仔细阅读并了解本隐私政策，在确认充分理解并同意后使用我们的产品或服务。如果您不同意本隐私政策中的任何内容，请立即停止使用或访问我们的产品和服务。若您对本政策有任何问题，请联系：support@akuvox.com
        </p>
        <p><strong>适用范围</strong></p>
        <p>（1）通过ccloud.akuvox.com、ccloud.akubela.com（“网站”）访问云服务网站和用户帐户；</p>
        <p>（2）在云服务网站上访问的用户帐户（“帐户”）物业经理帐户和最终用户账户；</p>
        <p>（2）通过网站访问的在线服务（“网页服务”）；</p>
        <p>（3）下载到移动设备（“移动应用”）的软件应用，包括但不限于“小睿”、“BelaHome”等；</p>
        <p>（4）我们提供的产品（“产品”）不限于门口机，室内机，安卓视频话机，门禁终端和家庭控制面板；</p>
        <p>（5）网站和移动应用程序上提供的任何服务（“可用服务”）</p>
        <p>（6）网站，网页应用程序和移动应用程序上可用的任何服务（“可用服务”）。术语“服务”是指网站，网页服务，移动应用程序，产品和可用服务。</p>
        <p><strong>政策要点</strong></p>
        <p>一、 我们如何收集和使用您和/或您的终端用户的个人信息</p>
        <p>二、 我们为什么要收集信息</p>
        <p>三、 您的权利</p>
        <p>四、 信息的存储</p>
        <p>五、 我们如何使用 Cookie 和同类技术</p>
        <p>六、 保护您的个人信息</p>
        <p>七、 我们如何管理您的个人信息</p>
        <p>八、 未成年人个人信息保护</p>
        <p>九、 隐私政策的修订与更新</p>
        <p>十、 如何联系我们</p>
        <p>我们深知个人信息对您的重要性，并会尽力保证您的个人信息安全可靠。
            一旦您开始使用睿云联服务，即表示您已充分理解并同意本政策，并同意我们按照本隐私政策收集、使用、保存、披露和转让您的相关信息。在我们更新本政策后（我们会及时提示您更新的情况），您继续使用我们的产品或者服务，即意味着您同意本政策(含更新版本)内容。如果您有任何的疑问、意见或者建议，您可通过下方提供的联系方式与我们联系。
        </p>
        <p><strong>一、 我们如何收集和使用您和/或您的终端用户的个人信息</strong></p>
        <p><strong>个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。</strong></p>
        <p>我们会依据引言中所提及的相关法律法规，遵循正当、合法、必要的原则，出于本政策所述的以下目的，收集和使用您在使用本平台过程中主动提供的、因您使用本平台而产生的以及我们从第三方获取的您和/或您的终端用户的个人信息。</p>
        <p>无论通过上述何种方式收集和使用您和/或您的终端用户的个人信息，我们仅会出于本政策所述的业务功能，收集和使用您的个人信息。我们通常只会在征得您同意的情况下进行，除非在某些情况下，基于法律义务或者可能需要保护您或其他人的重要利益向您和/或您的终端用户收集个人信息。
        </p>
        <p>请您理解，由于商业策略的变化，本平台上的产品和／或服务的功能也可能进行更迭和发展。如果我们要将您和/或您的终端用户的个人信息用于本政策未载明的其它用途，或基于特定目的收集的个人信息将用于其他目的，我们会通过本平台以合理的方式向您告知，并在使用前再次征得您的同意。
        </p>
        <p><strong>1．向您提供产品和/或服务中您主动提供的个人信息：</strong></p>
        <p>为实现本平台的基本业务功能，我们可能需要向您和您终端用户收集相关个人信息。以下将详细列出我们的基本业务功能及为实现该功能所需收集的个人信息，若您或您终端用户拒绝提供，则无法使用本平台的相关产品和/或服务。</p>
        <p>当您注册移动应用账号，网页服务时，您会向我们提供个人信息，<strong>其中包括用户名，电话号码，电子邮件，身份验证信息，设备和手机IP地址信息，设备MAC地址信息，浏览器和手机Cookies，日志信息和其他个人信息（基于您的同意情况下）</strong>。
        </p>
        <p><strong>2．向您提供产品和/或服务中我们收集和使用您终端用户的相关个人信息：</strong></p>
        <p>以下将详细列出我们向您提供产品和/或服务过程中收集和使用的个人信息。这些个人信息的收集仅会在您确认已获得了终端用户同意，且终端用户并未向我们提出拒绝接受服务，亦未向我们要求改变授权/删除个人信息/注销账户的前提下进行：
        </p>
        <p>我们的基本业务功能及为实现该功能所提供的网络服务必需收集的个人信息，我们会通过调用系统相关接口，收集您终端用户的信息，若您或您终端用户拒绝提供，则无法正常使用本平台的相关产品和/或服务，我们获取的终端信息如下：</p>
        <p>1) 基于我们为您提供视频通话、人脸采集、二维码扫描登录的服务，我们会获取：<strong>相机权限</strong></p>
        <p>2) 基于我们为您提供音视频通话的服务，我们会获取：<strong>录音权限</strong></p>
        <p>3) 基于我们为您提供落地号码的服务，我们会获取：<strong>访问联系人信息权限、修改联系人信息权限</strong></p>
        <p>4) 基于我们为您提供手机号登录的服务，我们会获取：<strong>手机号信息</strong></p>
        <p>5) 基于我们为您提供日志导出、人脸图片上传、二维码登录的服务，我们会获取：<strong>读取存储权限</strong></p>
        <p>6) 基于我们为您提供日志导出的服务，我们会获取：<strong>写存储权限</strong></p>
        <p>7) 基于我们为您提供消息推送的服务，我们会获取：<strong>通知权限</strong></p>
        <p>8) 基于我们为您提供落地呼叫的服务，我们会获取：<strong>电话权限</strong></p>
        <p>9) 基于我们为您提供终端用户服务的兼容性处理，我们会获取：<strong>系统运营信息</strong>；</p>
        <p>10) 基于我们对网络链路的选择与优化，我们会获取：<strong>网络状态信息</strong>；</p>
        <p>11) 基于我们为您提供的离线接收通话功能，我们需要使用<strong>自启动服务</strong>；</p>
        <p>12) 基于我们为您终端用户设备进行唯一性标识，我们会获取：<strong>MAC地址、国际移动设备识别码（IMEI）、匿名设备标识符(OAID)、国际移动用户识别码（IMSI）</strong>；</p>
        <p>13) 基于我们为您检测并实现ID同步及合并链路的服务，我们会获取：<strong>应用列表信息</strong>；</p>
        <p>14) 我们为了实现【小米消息推送】服务，我们集成【小米推送SDK】，需要收集使用您的<strong>【IMEI/OAID（适用于Android Q以上系统）、IMEI MD5/MEIDMD5、Android
            ID、VAID、AAID，以及您的MID（适用于MIUI系统）、设备型号】等信息</strong>。</p>
        <p>15) 为便于您更好地理解【小米推送SDK】个人信息收集使用规则，您可以登录或访问【小米开放平台】了解其《隐私政策》。</p>
        <p>16) 我们为了实现【华为消息推送】，集成了【华为推送SDK】，需要收集使用您的<strong>【IMEI/OAID（适用于Android Q以上系统）、IMEI MD5/MEIDMD5、Android
            ID、VAID、AAID，以及您的设备型号等信息</strong>。</p>
        <p>17) 为了方便您更好地理解【华为推送SDK】个人信息收集使用规则，您可以登录或访问【华为开发者联盟】了解其《隐私政策》。</p>
        <p>18) 我们为了实现【VIVO消息推送】，集成了【VIVO推送SDK】，需要收集使用您的<strong>【IMEI/OAID（适用于Android Q以上系统）、IMEI MD5/MEIDMD5、Android
            ID、VAID、AAID，以及您的设备型号等信息</strong>。</p>
        <p>19) 我们为了实现【OPPO消息推送】，集成了【OPPO推送SDK】，需要收集使用您的<strong>【IMEI/OAID（适用于Android Q以上系统）、IMEI MD5/MEIDMD5、Android
            ID、VAID、AAID，以及您的设备型号等信息</strong>。</p>
        <p>20) 我们为了实现【魅族消息推送】，集成了【魅族推送SDK】，需要收集使用您的<strong>【IMEI/OAID（适用于Android Q以上系统）、IMEI MD5/MEIDMD5、Android
            ID、VAID、AAID，以及您的设备型号等信息</strong>。</p>
        <p>21) 我们为了实现【社交分享】，集成了【微信Open
            SDK】，关于微信开放平台所收集的信息种类、用途、个人信息保护的规则及退出机制等，详见微信开放平台官网（https://open.weixin.qq.com）上的个人信息处理规则
            （https://support.weixin.qq.com/cgi-bin/mmsupportacctnodeweb-bin/pages/RYiYJkLOrQwu0nb8）。</p>
        <p>22) 我们为了实现【奔溃信息收集】，集成了【腾讯Bugly SDK】，需要<strong>收集日志信息、设备 ID（包括：androidid
            以及idfv）、联网信息、系统名称、系统版本以及国家码信息</strong>。</p>
        <p>23) 基于我们为您提供蓝牙开门的服务，我们会获取：<strong>蓝牙权限、位置权限；收集传感器；使用自启动服务</strong>。</p>
        <p>24) 为保证音视频功能的正常使用，在您首次使用我们的产品时，我们会向您申请<strong>悬浮窗权限</strong>。</p>
        <p>25) 我们为了实现天地伟业NVR监控和回放功能，集成了阿里云、阿里推送、mPaaS等SDK，需要收集使用您的<strong>IP、GAID等信息</strong>。</p>
        <p><strong>3．非个人隐私信息：</strong></p>
        <p><strong>根据适用的当地法律，我们还可能收集其他无法识别特定个人的信息，这些信息不是个人信息。这包括但不限于您使用网页服务，移动应用时生成的统计数据，与匿名设备有关的信息，每日事件，页面访问事件，页面访问持续时间事件，会话事件，网络监控数据（例如请求持续时间，请求和错误请求的数量，以及应用程序崩溃事件。</strong>收集这些信息是为了改善我们为您提供的睿云联服务。收集的信息的类型和数量取决于您使用睿云联服务的方式。
        </p>
        <p><strong>您充分知晓，以下情形中，我们收集、使用个人信息无需征得您的授权同意：</strong></p>
        <p>1) 与国家安全、国防安全直接相关的；</p>
        <p>2) 与公共安全、公共卫生、重大公共利益直接相关的；</p>
        <p>3) 与犯罪侦查、起诉、审判和判决执行等直接相关的；</p>
        <p>4) 出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
        <p>5) 所收集的个人信息是个人信息主体自行向社会公众公开的；</p>
        <p>6) 从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；</p>
        <p>7) 根据您的要求签订合同所必需的；</p>
        <p>8) 用于维护所提供的产品与/或服务的安全稳定运行所必需的，例如发现、处置产品与/或服务的故障；</p>
        <p>9) 为合法的新闻报道所必需的；</p>
        <p>10) 学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；</p>
        <p>11) 法律法规规定的其他情形。</p>
        <p><strong>二、我们为什么要收集信息</strong></p>
        <p>1.用于维护，改进，开发我们的产品或为您提供服务，例如交付，验证，售后和客户支持；</p>
        <p>2.数据用于身份验证，客户服务，安全性，归档和备份目的，以确保我们提供给您的睿云联服务的安全性；</p>
        <p>3.用于内部用途，例如数据分析，与您使用网页应用程序有关的统计信息的研究和开发，移动应用程序，以更好地改善睿云联服务；</p>
        <p>4.为您提供更灵活的服务</p>
        <p>5.进行软件验证和升级服务；</p>
        <p>6.提供用户所需的特定服务，这可能需要向我们的会员，第三方或其他用户提供信息；</p>
        <p>7.其他不违反强制性法律法规的情况；</p>
        <p>8.存储和维护有关您的信息以进行我们的业务运营（例如业务统计）或履行法律义务；</p>
        <p><strong>三、您的权利</strong></p>
        <p>睿云联将根据本政策以及法律法规的要求，将您的个人信息存储在睿云联的服务器上，以帮助睿云联更好的向您提供服务，当您使用睿云联服务时，您具有以下权利：</p>
        <p>1.同意或撤回您的同意：您可以随时撤回您的同意。</p>
        <p>2.获取，更新和删除您的信息：您可以在您的账户设置中，获取、更新或是删除您的个人信息，睿云联将协助您维护您的账户；</p>
        <p><strong>四、信息的存储</strong></p>
        <p>信息存储的地点</p>
        <p>我们会按照法律法规规定，将境内收集的用户个人信息存储于中国境内。</p>
        <p>1.存储的时间</p>
        <p>我们会根据数据的类型和使用方式来确定不同的存储时间。我们将阐述在不同的时间段内存储不同类型的数据的原因：</p>
        <p>1) 我们根据收集原因为不同类型的数据设置不同的存储时间范围，例如，有访客来访摄像头截图，感应截图和呼叫截图，数据将会在三个月后自动从服务器上删除。</p>
        <p>2) 信息保留，直到您删除了账号</p>
        <p>我们会保留一些数据来为您提供睿云联服务，例如，存储在我们服务器上的设备的MAC和IP，呼叫历史记录，系统日志和最终用户帐户，安装商帐户，分销商帐户或物业管理帐户信息。</p>
        <p>3)根据业务和法律要求而存储较长时间的信息</p>
        <p>有时，出于业务和法律要求，我们有义务为了特定目的长时间保留某些信息。我们可能会较长时间保留某些数据的原因包括财务记录保存和遵守法律或法规要求。</p>
        <p><strong>五、我们如何使用 Cookie 和同类技术</strong></p>
        <p>Cookies是包含文件字符串的小文件，当您访问网站时会发送到您的计算机。当您再次访问网站时，cookie使网站能够识别您的浏览器。
            Cookies也可以存储用户首选项和其他信息。您可以重置浏览器以拒绝所有cookie或在发送cookie时提醒您。但是，如果没有Cookie，某些网站功能或服务可能无法正常运行。</p>
        <p>Cookies和类似技术是互联网上的常用技术。当您使用网页服务，移动应用时，我们可能会使用这些技术向您的设备发送Cookie或匿名标识符，以在您访问和使用网页服务，移动应用时收集和存储信息。除非您的当地法律将互联网协议（IP）地址或类似的识别标记视为个人信息，否则我们将通过Cookie和其他技术收集的信息视为非个人信息。我们将Cookie和类似技术用于以下睿云联服务：
        </p>
        <p>1．确保睿云联服务的安全有效运行</p>
        <p>我们可能会设置身份验证或安全cookie或匿名标识符，以帮助我们确认您已安全登录该服务或可能遇到诸如欺诈之类的非法行为。这些技术还将帮助我们提高服务效率以及系统登录和响应速度。</p>
        <p>2．帮助您获得更轻松的访问体验</p>
        <p>使用此类技术可以帮助您避免重复填写个人信息以及通过一键式登录和搜索历史之类的方法输入搜索内容的步骤和过程。</p>
        <p>3．对于日志文件</p>
        <p>与大多数网站一样，我们收集某些信息并将其保存在日志文件中。此类信息可能包括互联网协议（IP）地址，浏览器类型，互联网服务提供商（ISP），引荐/退出页面，操作系统，日期/时间戳和/或点击流数据。我们不会将自动收集的日志数据与我们收集的其他信息相关联。
        </p>
        <p>4．用于移动分析</p>
        <p>在移动应用程序中，我们使用移动分析软件来更好地了解您手机中移动软件的功能。该软件可能会记录信息，例如您使用该应用程序的频率，该应用程序内发生的事件，累积使用情况，性能数据以及应用程序崩溃发生位置的数据。我们不会将存储在分析软件中的信息与您在网页服务，移动应用中提交的任何个人信息相关联。
        </p>
        <p><strong>六、保护您的个人信息</strong></p>
        <p>所有睿云联服务都具有内置的强大安全功能。 我们制定了严格的信息管理政策，配备了专业的技术团队，并采取一系列合理的预防措施来保护您的个人信息免遭未经授权的浏览，披露，滥用，篡改，破坏和丢失，包括：</p>
        <p>1.使用加密技术来确保您的数据在传输过程中保持私密性；</p>
        <p>2.提供多种安全功能，以帮助您保护帐户安全；</p>
        <p>3.审查我们对信息实践的收集，存储和处理，包括物理安全措施，以防止未经授权的人员访问我们的系统；</p>
        <p><strong>七、我们如何管理您的个人信息</strong></p>
        <p>1.共享</p>
        <p>1) 我们可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您和/或您的终端用户的个人信息。</p>
        <p>2) 在法律要求或允许的范围内，为了保护您或社会公众的利益、财产或安全免遭损害而有必要提供您和/或您的终端用户的个人信息给第三方（含消费者权益保护部门等行业组织）；</p>
        <p>3) 为了保护国家安全、公共安全以及您和其他个人的重大合法权益而需要共享您和/或您的终端用户的个人信息；1.通常，未经您的同意，我们不会与睿云联以外的任何组织或个人共享个人信息；</p>
        <p>2.转让</p>
        <p>我们不会将您或您的终端用户的个人信息转让给任何公司、组织和个人，但以下情况除外：</p>
        <p>1) 在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；</p>
        <p>2) 为了保护国家安全、公共安全以及您和其他个人的重大合法权益而需要转让的；</p>
        <p>3)
            在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会在要求新的持有您个人信息的公司、组织继续受此隐私政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。如果本声明中约定的个人信息的收集、处理方式发生任何改变，该公司、组织将重新向您征求授权同意。
        </p>
        <p>3.公开披露</p>
        <p>我们仅会在以下情况下，公开披露您或您的终端用户的个人信息：</p>
        <p>1) 获得您明确同意后；</p>
        <p>2) 基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您或您的终端用户的个人信息。</p>
        <p>依法豁免征得同意共享、转让、公开披露的个人信息</p>
        <p>4.账号注销</p>
        <p>您可以联系您的物业管理员或服务提供商进行账号注销，在您的帐号注销之后，我们将停止为您提供全部或部分服务，且一并删除您的个人信息。</p>
        <p>请您理解，在下列情形中，根据法律法规及国家标准，我们共享、转让、公开披露您和/或您的终端用户的个人信息无需征得您的授权同意：</p>
        <p>1、与国家安全、国防安全直接相关的；</p>
        <p>2、与公共安全、公共卫生、重大公共利益直接相关的；</p>
        <p>3、与犯罪侦查、起诉、审判和判决执行等直接相关的；</p>
        <p>4、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
        <p>5、您自行向社会公众公开的个人信息；</p>
        <p>6、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</p>
        <p><strong>八、未成年人个人信息保护</strong></p>
        <p>1.我们非常重视对未成年人个人信息的保护。若您是18周岁以下的未成年人，在使用我们的产品与/或服务前，应事先取得您父母或法定监护人的书面同意。我们根据国家相关法律法规的规定保护未成年人的个人信息。</p>
        <p>2.对于经父母或法定监护人同意而收集未成年人个人信息的情况，我们只会在受到法律允许、父母或法定监护人明确同意或者保护未成年人所必要的情况下使用或公开披露此信息。</p>
        <p>3.如果我们发现自己在未事先获得可证实的父母或法定监护人同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关数据。</p>
        <p><strong>九、隐私政策的修订与更新</strong></p>
        <p>我们可能会适时对本政策进行变更。未经您明确同意，我们不会削减您按照本政策所应享有的权利。我们会在本页面上发布对本政策所作的任何变更。 对于重大变更，我们会以推送通知、弹窗、发送电子邮件等的形式通知您。</p>
        <p><strong>十、如何联系我们</strong></p>
        <p>如果您对我们对您的个人数据的使用有任何疑问，请发送邮件到support @ akuvox.com或直接拨打我们信息保护部门电话：+86 592 2133061 转分机号：7661与我们联系。</p>
    </div>
</template>

<style lang="scss" scoped>
p {
    margin-top: 10px;
}
.policy-ul {
    left: 20px;
    position: relative;
    width: 90%;
    li {
        list-style: disc;
    }
}
</style>
